<template lang="pug">
.container
  nuxt-error(:error="error")
</template>

<script>
import NuxtError from '~/components/NuxtError'

export default {
  components: {
    NuxtError
  },
  asyncData({ params }) {
    return {
      error: {
        statusCode: Number(params.code),
        message: ''
      }
    }
  }
}
</script>
